import CircularProgress from '@mui/material/CircularProgress';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import React,{useMemo, useEffect, useState, useRef } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Pagination } from '@mui/material';
import {useParams} from 'react-router-dom'
import { CategoryScale } from 'chart.js';
import { Bar, Line,Pie } from 'react-chartjs-2'
import CircleIcon from '@mui/icons-material/Circle';
import PrismaZoom from 'react-prismazoom';
import SearchIcon from '@mui/icons-material/Search';
import Chart from 'chart.js/auto';
import '../Admin/adminstats.css'
import moment from 'moment';
import 'moment/locale/ru';
import Cookies from 'js-cookie'
import Table from './Table'
import { address, address_with_api } from '../settings';

Chart.register(CategoryScale);
const Adminstat = () => {
    const [bigDiagramm,setBigDiagramm] = useState(false)
    const [podMas,setPodMas]=useState([])
    const [page,setPage]=useState(1)
    const [pages,setPages] =useState(1)
    const [elements,setElements] = useState(100)
    const [KeyArray,setKeyArray] = useState([])
    const token = Cookies.get('_auth')
    const [activeUsers, setActiveUsers] = useState([])
    const [inActiveUsers, setInActiveUsers] = useState([])
    const [researches,setResearches] = useState([])
    const [researchBackup,setResearchBackup] = useState([])
    const [users,setUsers] = useState([])
    const [backupUsers,setBackupUsers] = useState([])
    const [detalizationShown,setDetalizationShown] = useState(false)
    const [imageLoading,setImageLoading] = useState(false)
    const [tableLoading,setTableLoading] = useState(true)
    const [tableHidden,setTableHidden] = useState(true)
    const [selectedUsers,setSelectedUsers] = useState({})
    const [normCount,setNormCount] = useState(0)
    const [totalCount,setTotalCount] = useState()
    const [counter,setCounter] = useState(0)
    const [detailCount,setDetailCount] = useState(0)
    const [url,setUrl]=useState('')
    const [detalizationLoading,setDetalizationLoading] = useState(false)
    const [pathologies,setPathologies] = useState({})
    const [mas,setMas] = useState([])
    const id = Object.values(useParams())
    const colors = ['#C71585','#00FFFF','#00FF00','#FF4500','#DC143C','#FF00FF','#4B0082','#0000CD','#2F4F4F','#708090','#7B68EE','#4169E1','#00BFFF','#5F9EA0','#40E0D0','#30BA8F','#FB607F','#1DACD6','#FADBC8','#753313','#3D642D','#CC7722','#EE204D',"#A5694F","#2A52BE","#76FF7A","#310062","#270A1F","#801818","#FF43A4","#FF8C69","#1CD3A2","#CD00CD","#E28B00","#F7F21A","#8C4743","#1A4876","#4CBB17","#022027","#FFE5B4","#6DAE81"]
    const [loading,setLoading] = useState(true)
    const [setting,setSetting] = useState(false)
    const [graphLoading,setGraphLoading] = useState(true)
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);
    const [bigTable,setBigTable] = useState(false)
    const [imageId,setImageId] = useState(0)
    const [commentText,setCommentText] = useState('')
    const [comments,setComments] = useState()
    const [showCommentWindow,setShowCommentWindow] = useState(false)
    const [radioValue,setRadioValue] = useState(-1)
    const [rate,setRate] = useState(-1)
    const [radioSubValue,setRadioSubValue] = useState(-1)
    const [userRate,setuserRate] = useState('-1')
    const [commentsLoading,setCommentsLoading] = useState(true)
    const [itemMode,setItemMode] = useState(0)
    const [showComments,setShowComments] = useState(false)
    const [fileName,setFileName] = useState('')
    const [showDownloadPanel,setShowDownloadPanel] = useState(false)
    const [errors,setErrors] = useState()
    const [userError,setUserError] = useState({
        "FP":"0",
        "FN":"0",
        "WL":"0",
        "WC":"0",
    })
    const [selectedPeriod, setSelectedPeriod] = useState(moment().subtract(1,'month').format("YYYY-MM-DD"));

    const splitImage = (type) =>{
        const file = fileName;

        const downloadImage = (blob, fileName) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        };
      
        if (type === 'concat') {
          const link = document.createElement('a');
          link.download = `${file}.jpeg`;
          link.href = url;
          link.click();
          return 0;
        }
      
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = url;
      
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = image.width / 2;
          canvas.height = image.height;
      
          if (type === 'original') {
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => downloadImage(blob, `${file}_original.jpeg`), 'image/jpeg');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
          }
      
          if (type === 'result') {
            ctx.drawImage(image, canvas.width, 0, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => downloadImage(blob, `${file}_result.jpeg`), 'image/jpeg');
          }
        };
    }
    const [columns,setColumns] = useState({
        full_name:true,
        file:false,
        datetime:true,
        prob:true,
        sopinstanceuid:false,
        studyinstanceuid:true,
        proccessing_time:true,
        classes:true,
        raw_prob:false,
    })
    const [sort,setSort] = useState({
        name:'datetime',
        direction:false  //True = на возрастание; False = на убывание 
    })
    document.addEventListener('keydown',function(e){
        if(e.key=='Escape'||e.key=='Esc'||e.key==27){setIsImgEditorShown(false);setDetalizationShown(false)}
    })
    useEffect(()=>{
        document.getElementById('end').value= moment().format('YYYY-MM-DD')
        document.getElementById('start').value= moment().date(1).format('YYYY-MM-DD')
        let date = moment().format("YYYY-MM-DD HH:mm:ss")
        let obj={}
        fetch(address+'/users/for/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer ' + token,
        },
          body:'{"date":"'+date+'"}'
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
            if(result!==false){
            setUsers(result.filter((item)=>moment(item.datetime).isAfter(selectedPeriod)))
            setBackupUsers(result)
            // setUsers(result)
            result.map((item)=>obj[`${item.id}`]=false)
            setSelectedUsers(obj)
            setLoading(false)
            setGraphLoading(false)
          }
            else alert("Неверно введённые данные")
        })
        checkActiveUsers()
        const interval = setInterval(()=>{
            checkActiveUsers()
        },180000)
        return () => clearInterval(interval);
    },[])
    const handleChange = () => { // Обработчик события изменения селектора выбора периода времени
        switch(document.getElementById('ad-select').value){
            case 'default':
                break;
            case 'current_week':
                document.getElementById('start').value=moment().clone().startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().clone().endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'last_week':
                document.getElementById('start').value=moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'current_month':
                document.getElementById('start').value=moment().startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('month').format('YYYY-MM-DD');
                break;
            case 'previous_month':
                document.getElementById('start').value=moment().add(-1,'month').startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'month').endOf('month').format('YYYY-MM-DD');
                break;
            case 'current_quarter':
                document.getElementById('start').value=moment().startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'previous_quarter':
                document.getElementById('start').value=moment().add(-1,'quarter').startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'quarter').endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'current_year':
                document.getElementById('start').value=moment().startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('year').format('YYYY-MM-DD');
                break;
            case 'previous_year':
                document.getElementById('start').value=moment().add(-1,'year').startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'year').endOf('year').format('YYYY-MM-DD');
                break;
            default:break
        }
        sendData()
    }
    const sortTable = (field) =>{
        let masiv = []
        if(sort.name===field){
            if (sort.direction){
                if(field=='datetime')masiv = [...researches].sort((a,b)=>moment(b['datetime'])-moment(a['datetime']))
                if(field=='prob')masiv = [...researches].sort((a,b)=>b['prob']-a['prob'])
                if(field=='studyinstanceuid')masiv = [...researches].sort((a,b)=>a['studyinstanceuid'].localeCompare(b['studyinstanceuid']))
                if(field=='organization')masiv = [...researches].sort((a,b)=>a['organization'].localeCompare(b['organization']))
                if(field=='classes')masiv = [...researches].sort((a,b)=>a['classes'].localeCompare(b['classes']))
                setSort({...sort,['name']:field,['direction']:false})
                setResearches(masiv)
            }
            if(!sort.direction){
                if(field=='datetime')masiv = [...researches].sort((a,b)=>moment(a['datetime'])-moment(b['datetime']))
                if(field=='prob')masiv = [...researches].sort((a,b)=>a['prob']-b['prob'])
                if(field=='studyinstanceuid')masiv = [...researches].sort((a,b)=>b['studyinstanceuid'].localeCompare(a['studyinstanceuid']))
                if(field=='organization')masiv = [...researches].sort((a,b)=>b['organization'].localeCompare(a['organization']))
                if(field=='classes')masiv = [...researches].sort((a,b)=>b['classes'].localeCompare(a['classes']))
                setResearches(masiv)
                setSort({...sort,['name']:field,['direction']:true})
            }
        }
        else{
            if(field=='datetime')masiv = [...researches].sort((a,b)=>moment(b['datetime'])-moment(a['datetime']))
            if(field=='prob')masiv = [...researches].sort((a,b)=>b['prob']-a['prob'])
            if(field=='studyinstanceuid')masiv = [...researches].sort((a,b)=>a['studyinstanceuid'].localeCompare(b['studyinstanceuid']))
            if(field=='organization')masiv = [...researches].sort((a,b)=>a['organization'].localeCompare(b['organization']))
            if(field=='classes')masiv = [...researches].sort((a,b)=>a['classes'].localeCompare(b['classes']))
            setSort({...sort,['name']:field,['direction']:false})
            setResearches(masiv)
        }
    }
    const handleSelect = () => {//Обработчик изменений полей для ввода даты
        if (moment(document.getElementById('start').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek')))document.getElementById('ad-select').value='current_week'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('month').add(-1,'day'),moment().endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('month'),moment().endOf('month')))document.getElementById('ad-select').value='current_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'month').startOf('month').add(-1,'day'),moment().add(-1,'month').endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')))document.getElementById('ad-select').value='previous_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter')))document.getElementById('ad-select').value='current_quarter'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter')))document.getElementById('ad-select').value='previous_quarter'
        else document.getElementById('ad-select').value='random';
            if(moment(document.getElementById('start').value).isAfter(moment().subtract(1,'month')))setSelectedPeriod(moment().subtract(1,'month').format('YYYY-MM-DD'))
            else if(moment(document.getElementById('start').value).isAfter(moment().subtract(2,'month')))setSelectedPeriod(moment().subtract(2,'month').format('YYYY-MM-DD'))
            else if(moment(document.getElementById('start').value).isAfter(moment().subtract(6,'month')))setSelectedPeriod(moment().subtract(6,'month').format('YYYY-MM-DD'))
            else setSelectedPeriod(moment('2000-01-01 00:00:00','YYYY-MM-DD').format('YYYY-MM-DD'))
            let mas = []
            mas = [...backupUsers].filter((item)=>moment(item.datetime).isAfter(moment(document.getElementById('start').value)))
            setUsers(mas)
    }
    const sel = () =>{
        var val = document.getElementById('selectall').checked
        let obj ={}
        Object.keys(selectedUsers).map(item=>parseInt(item)).map(item=>obj[item]=val)
        setSelectedUsers(obj)
    }
    const sendData = () => {//отправка данных о периоде и получение статистики по выбранным пользователям
        let mas = Object.keys(selectedUsers).filter((item)=>selectedUsers[item]).map((item)=>parseInt(item))        
        setDetalizationShown(false)
        if(mas.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        if (mas.length===1){
            document.getElementById('check').checked=false
        }
        var modif = document.getElementById('check').checked?"comparative":"general"
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        setGraphLoading(true)
        let body={
            "id_mas":{"mas":mas},
            "mode":modif,
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":elements*page
        }
        fetch(address+'/get/users/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:JSON.stringify(body)
        }).then(res=>{if(res.ok) return res.json()}).then(resp=>{
            var massive=[]
            setKeyArray(Object.keys(Object.values(resp[0])[0]))
            let obj={data: [],borderColor: ['blue',],borderWidth: 3,tension: 0,hoverRadius: 5,hoverBackgroundColor: 'blue'}
            let login = Object.keys(resp[0])
            let values = Object.values(resp[0]).map((item=>Object.values(item)))
            if(!document.getElementById('check').checked)
            {
                obj.hoverBackgroundColor=colors[1]
                obj.label='Исследований'
                obj.data=values[0]
                obj.borderColor=[colors[1]]
                massive.push(obj)
            }
            else login.map((item,index)=>{
                obj.hoverBackgroundColor=colors[index]
                obj.label=item
                obj.data=values[index]
                obj.borderColor=[colors[index]]
                massive.push(obj)
                obj={label: '',data: [],borderColor: ['blue',],borderWidth: 3,tension:0,hoverRadius:5,hoverBackgroundColor:'blue',}
            })
            setPage(1)
            setMas(massive)
            setCounter(resp[1])
            setGraphLoading(false)
            setLoading(false)
        })
        setTableHidden(false)
        setTableLoading(true)
        fetch(address+'/get/users/researches', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
                },
              body:JSON.stringify(body)})
                .then(resp=>{if(resp.ok)return resp.json()})
                    .then(result=>{
                        setResearchBackup(result)
                        setPages(1)
                        let podmas=[]
                        for (let i = 0; i <Math.ceil(result.length/elements); i++){
                            podmas.push(result.slice((i*elements), (i*elements) + elements));
                        }
                        setPodMas(podmas)
                        setResearches(podmas[0])
                        setTableLoading(false)
                    })
    }
    const searchStudy = (mode) => {
        const study = document.getElementById('research').value || '';
        if (study === '') {
            alert('Поле для поиска не должно быть пустым')
            return 0;
        }
        setTableLoading(true)
        let body = {
            uid: study
        }
        fetch(address_with_api + '/search/study', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(body)
        }).then(result => result.json())
            .then(json => {
                setResearches(json);
                document.getElementById("research").value = '';
                setTableLoading(false)
            })
            .catch(err => {
                console.log(err)
            })
    }
    const closeImgEditor  = () => {//Закрытие/открытие изображения
        setShowComments(false)
        setRate(-1)
        setRadioValue(-1)
        setRadioSubValue(-1)
        setIsImgEditorShown(false);
        setShowDownloadPanel(false)
    };   
    const getImage = (id,file) => {
        setCommentsLoading(true)
        setImageId(id)
        setFileName(file)
        setShowComments(false)
        setShowCommentWindow(false)
        setIsImgEditorShown(true)
        setSetting(false)
        setImageLoading(true)
        let mas=[]
        document.getElementById(id).style.backgroundColor='lightskyblue'
        let body={
            "images_id":[id],
        }
        fetch(address+'/get/users/comments', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
        },
          body:JSON.stringify(body)
        }).then(response=>response.json()).then(result=>{
            let comment_mas = Object.values(result)[0].comment
            if(comment_mas.length==1 && comment_mas[0]==''){
                setComments(['Нет'])
            }
            else{
                setComments(comment_mas)
            }
            setUserError({
                "FP":Object.values(result)[0]["FP"],
                "FN":Object.values(result)[0]["FN"],
                "WC":Object.values(result)[0]["WC"],
                "WL":Object.values(result)[0]["WL"],
            })
            if(Object.values(result)[0]["FP"]==1)mas.push("ложноположительный результат")
            if(Object.values(result)[0]["FN"]==1)mas.push("ложноотрицательный результат")
            if(Object.values(result)[0]["WL"]==1)mas.push("неверное оконтуривание")
            if(Object.values(result)[0]["WC"]==1)mas.push("неверная классификация патологий")
            setErrors(mas)
            if(Object.values(result)[0].user_rate =='1') setuserRate(1)
            else if (Object.values(result)[0].user_rate =='0') setuserRate(0)
            else setuserRate(-1)
            setCommentsLoading(false)
        })
        setShowComments(false)
        fetch(address_with_api+'/get/research/image', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+token,
                'Content-Type': 'application/json',
                'Conteny-type':'image/png'
            },
            body:'{"id":'+id+'}'}).then(resp=>{if(resp.ok) return resp; else return false}).then((response) => {
                if(response!=false) {const reader = response.body.getReader();
                return new ReadableStream({
                  start(controller) {
                    return pump();
                    function pump() {
                      return reader.read().then(({ done, value }) => {
                        if (done) {
                          controller.close();
                          return;
                        }
                        controller.enqueue(value);
                        return pump();
                      });
                    }
                  }
                })}
                else {
                    setImageLoading(false);
                    return 0}
              }).then((stream) => new Response(stream)).then((response) => response.blob()).then((blob) => URL.createObjectURL(blob))
              .then((url) => {
                setUrl(url)
                setImageLoading(false)
                document.getElementById('image').src = url;
            });
    }
    const detalization = () =>{
        var id_massive = Object.values(document.getElementsByName('input')).filter((item)=>item.checked).map(item=>parseInt(item.id))
        if(id_massive.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        setTableHidden(true)
        setDetalizationShown(true)
        setDetalizationLoading(true)
        let id_mas = Object.values(document.getElementsByName('input')).filter((item)=>item.checked).map(item=>parseInt(item.id))
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        fetch(address_with_api+'/get/researches', {///api
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
            },
          body:'{"id_mas":{"mas":['+id_mas+']},"mode":"comparative","date_start":"'+start+' 00:00:00","date_end":"'+end+' 23:59:59"}'})
            .then(resp=>{if(resp.ok)return resp.json()})
                .then(result=>{
                    let reg = /[А-Яа-яA-Za-z\.,/\s]/gi
                    setDetailCount(result.length)
                    var classes = result.map((item)=>item.classes)
                    let str = JSON.stringify(classes).match(reg).join('').split(',').map((item)=>item.trim())
                    let object={}
                    str.forEach(function (x) { object[x] = (object[x] || 0) + 1; });
                    setNormCount(object[""])
                    delete object[""]
                    const ordered = Object.keys(object).sort().reduce(
                        (obj, key) => {
                          obj[key] = object[key];
                          return obj;
                        },
                        {}
                    );
                    setDetalizationLoading(false)
                    setPathologies(ordered)
                })
    }
    const checkActiveUsers = () =>{
        fetch(address_with_api+'/get/active/users', {///api
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
            }}).then((result)=>result.json()).then(res=>{
                setActiveUsers(res.active_users)
                setInActiveUsers(res.inactive_users)
                setTotalCount(res.count)
            })
    }
    const handlePage = (event, value) => {
        setPage(value);
        setResearches(podMas[value-1])
    }
    const handleElements =() => {
        setTableLoading(true)
        let mas = Object.keys(selectedUsers).filter((item)=>selectedUsers[item]).map((item)=>parseInt(item))        
        let number = parseInt(document.getElementById("elements").value)
        if(mas.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        var modif = document.getElementById('check').checked?"comparative":"general"
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        setElements(number)
        let body={
            "id_mas":{"mas":mas},
            "mode":modif,
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":number*page
        }
        fetch(address+'/get/users/researches', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
            },
          body:JSON.stringify(body)})
            .then(resp=>{if(resp.ok)return resp.json()})
                .then(result=>{
                    setResearchBackup(result)
                    setPages(Math.ceil(result.length/number))
                    let podmas=[]
                    for (let i = 0; i <Math.ceil(result.length/number); i++){
                        podmas.push(result.slice((i*number), (i*number) + number));
                    }
                    setPodMas(podmas)
                    setResearches(podmas[0])
                    setTableLoading(false)
                    setPage(1)
                })
    }
    const nextPage =() => {
        setTableLoading(true)
        let mas = Object.keys(selectedUsers).filter((item)=>selectedUsers[item]).map((item)=>parseInt(item))        
        let number = parseInt(document.getElementById("elements").value)
        if(mas.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        var modif = document.getElementById('check').checked?"comparative":"general"
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        setElements(number)
        let body={
            "id_mas":{"mas":mas},
            "mode":modif,
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":(number*(page+1))
        }
        fetch(address+'/get/users/researches', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
                },
              body:JSON.stringify(body)})
                .then(resp=>{if(resp.ok)return resp.json()})
                    .then(result=>{
                        setResearchBackup(result)
                        setPages(Math.ceil(result.length/number))
                        let podmas=[]
                        for (let i = 0; i <result.length/number; i++){
                            podmas[i]=result.slice((i*number), (i*number) + number);
                        }
                        setPage(podmas.length)
                        setPodMas(podmas)
                        setResearches(podmas[podmas.length-1])
                        setTableLoading(false)
                    })
    }
    const hideTable = () =>{
        setTableHidden(true)
        setBigTable(false)
    }
    const organizationHandler = (e) =>{
        let masiv = []
        masiv = [...backupUsers].filter((item)=>item.organization.toLowerCase().indexOf(e.target.value.toLowerCase().trim())!=-1||item.full_name.toLowerCase().indexOf(e.target.value.toLowerCase().trim())!=-1)
        if(e.target.value=='')masiv = [...backupUsers].filter((item)=>moment(item.datetime).isAfter(selectedPeriod))
        setUsers(masiv)
    }
    const clearOrganization = () =>{
        document.getElementById('organization').value=''
        let masiv = [...backupUsers].filter((item)=>moment(item.datetime).isAfter(selectedPeriod))
        setUsers(masiv)
    }
    const columnHandler = (e) => {
        setColumns({...columns,[e.target.name]:!columns[e.target.name]})
    }
    const handleComment = (e) => {
        setCommentText(e.target.value)
    }
    const getStatistic =() => {
        let mas = Object.keys(selectedUsers).filter((item)=>selectedUsers[item]).map((item)=>parseInt(item))
        if(mas.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        var modif = document.getElementById('check').checked?"comparative":"general"
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        setGraphLoading(true)
        let body={
            "id_mas":{"mas":mas},
            "mode":modif,
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":elements*page
        }
        fetch(address+'/get/users/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:JSON.stringify(body)
        }).then(res=>{if(res.ok) return res.json()}).then(resp=>{
            var massive=[]
            setKeyArray(Object.keys(Object.values(resp[0])[0]))
            let obj={data: [],borderColor: ['blue',],borderWidth: 3,tension: 0,hoverRadius: 5,hoverBackgroundColor: 'blue'}
            let login = Object.keys(resp[0])
            let values = Object.values(resp[0]).map((item=>Object.values(item)))
            if(!document.getElementById('check').checked)
            {
                obj.hoverBackgroundColor=colors[1]
                obj.label='Исследований'
                obj.data=values[0]
                obj.borderColor=[colors[1]]
                massive.push(obj)
            }
            else login.map((item,index)=>{
                obj.hoverBackgroundColor=colors[index]
                obj.label=item
                obj.data=values[index]
                obj.borderColor=[colors[index]]
                massive.push(obj)
                obj={label: '',data: [],borderColor: ['blue',],borderWidth: 3,tension:0,hoverRadius:5,hoverBackgroundColor:'blue',}
            })
            setCounter(resp[1])
            setGraphLoading(false)
            setMas(massive)
        })
    }
    const sendComment = () => {
        if(commentText.length==0){
            window.alert('Нельзя отправить пустой комментарий')
            return 0
        }
        let new_comments = [...comments, commentText]
        let body ={
            images_id: imageId,
            comments: new_comments
        }
        fetch(address+'/users/comments/update', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
          },
            body:JSON.stringify(body)
          }).then(response=>response.status).then(result=>{
            if(result==200){
                if(comments[0]=='Нет'&&comments.length==1){
                    setComments([commentText])
                }
                else if(comments[0]=='Нет'&&comments.length>1){
                    new_comments.shift()
                    setComments(new_comments)
                }
                else{
                    setComments(new_comments)
                }
                setCommentText('')
                setShowCommentWindow(false)
            }
        })
    }
    const sendRate = () =>{
        if(radioValue===-1){alert('Выберите один из вариантов ошибки');return 0}
        if(radioSubValue===-1){alert('Выберите подпункт');return 0}
        let checkboxes = document.getElementsByName('detectcheckbox')
        let body={
            "images_id":imageId,
            "error":{
                "Rate":rate,
                "FP":0,
                "FN":0,
                "WL":0,
                "WC":0,
            }
        }
        for(var i=0; i<checkboxes.length; i++){
            if(checkboxes[i].checked)body["error"][checkboxes[i].value]=1
        }
        if(radioValue==1&&body["WL"]==0&&body["WC"]==0){alert('Выберите подпункт');return 0}
        try{
            body["error"][document.querySelector('input[name="detectradio"]:checked').value]=1;
        }
        catch{
        }
        fetch(address+'/users/comments/error/update', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
          },
            body:JSON.stringify(body)
        }).then(response=>response.status).then(result=>{if(result==200){
            setRate(body.error.Rate)
            setUserError({...userError,['FN']:body['error']['FN'],['FP']:body['error']['FP'],['WL']:body['error']['WL'],['WC']:body['error']["WC"]})
        }})
        if(commentText.length!==0){
            let new_comments = [...comments, commentText]
            let body ={
                images_id: imageId,
                comments: new_comments
            }
            fetch(address+'/users/comments/update', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
            },
                body:JSON.stringify(body)
            }).then(response=>response.status).then(result=>{
                if(result==200){
                    if(comments[0]=='Нет'&&comments.length==1){
                        setComments([commentText])
                    }
                    else if(comments[0]=='Нет'&&comments.length>1){
                        new_comments.shift()
                        setComments(new_comments)
                    }
                    else{
                        setComments(new_comments)
                    }
                    setCommentText('')
                    setShowCommentWindow(false)
                    setuserRate(0)
                }
            })
        }
    }
    const sendAgreement = () => {
        let body={
            "images_id":imageId,
            "error":{
                "Rate":rate,
                "FP":0,
                "FN":0,
                "WL":0,
                "WC":0,
            }
        }
        fetch(address+'/users/comments/error/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
            },
            body:JSON.stringify(body)
            }).then(response=>response.status).then(result=>{if(result==200){
                setCommentText('')
                setShowCommentWindow(false)
                setuserRate(1)
            }})
        if(commentText.length!==0){
            let new_comments = [...comments, commentText]
            let body ={
                images_id: imageId,
                comments: new_comments
            }
            fetch(address+'/users/comments/update', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
            },
                body:JSON.stringify(body)
            }).then(response=>response.status).then(result=>{
                if(result==200){
                    if(comments[0]=='Нет'&&comments.length==1){
                        setComments([commentText])
                    }
                    else if(comments[0]=='Нет'&&comments.length>1){
                        new_comments.shift()
                        setComments(new_comments)
                    }
                    else{
                        setComments(new_comments)
                    }
                    setCommentText('')
                    setShowCommentWindow(false)
                    setuserRate(1)
                }
            })
        }
    }
    const copyToClipboard = (item) => {
        let textarea = document.getElementById(item);
        textarea.select();
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        // alert(`${text} скопирован`);
    }
    const handlePeriodChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedPeriod(selectedValue);
        let masiv = []
        document.getElementById('organization').value = ''
        masiv = [...backupUsers].filter((item)=>moment(item.datetime).isAfter(selectedValue))
        setUsers(masiv)
    };
    return (
        <div className='admin-stat'>
            {setting&&
                <div className='columns-settings' id='setting'>
                    <div className='colimn-settings-title'>
                        <button onClick={()=>setSetting(false)}>Закрыть</button>
                    </div>
                    <div className='colimn-settings-data'>
                        <div className='colimn-settings-row' onClick={()=>setColumns({...columns,["full_name"]:!columns.full_name})}>
                            <input checked={columns.full_name} type='checkbox' name='full_name' onChange={columnHandler}/><p>Организация</p>
                        </div>
                        <div className='colimn-settings-row' onClick={()=>setColumns({...columns,["file"]:!columns.file})}>
                            <input checked={columns.file} type='checkbox' name='file' onChange={columnHandler}/><p>Название файла</p>
                        </div>
                        <div className='colimn-settings-row' onClick={()=>setColumns({...columns,["datetime"]:!columns.datetime})}>
                            <input checked={columns.datetime} type='checkbox' name='datetime' onChange={columnHandler}/><p>Дата</p>
                        </div>
                        <div className='colimn-settings-row' onClick={()=>setColumns({...columns,["prob"]:!columns.prob})}>
                            <input checked={columns.prob} type='checkbox' name='prob' onChange={columnHandler}/><p>Вероятность</p>
                        </div>
                        <div className='colimn-settings-row' onClick={()=>setColumns({...columns,["sopinstanceuid"]:!columns.sopinstanceuid})}>
                            <input checked={columns.sopinstanceuid} type='checkbox' name='sopinstanceuid' onChange={columnHandler}/><p>SopinstanceUID</p>
                        </div>
                        <div className='colimn-settings-row' onClick={()=>setColumns({...columns,["studyinstanceuid"]:!columns.studyinstanceuid})}>
                            <input checked={columns.studyinstanceuid} type='checkbox' name='studyinstanceuid' onChange={columnHandler}/><p>StudyUID</p>
                        </div>
                        <div className='colimn-settings-row' onClick={()=>setColumns({...columns,["proccessing_time"]:!columns.proccessing_time})}>
                            <input checked={columns.proccessing_time} type='checkbox' name='proccessing_time' onChange={columnHandler}/><p>Время обработки,c</p>
                        </div>
                        <div className='colimn-settings-row' onClick={()=>setColumns({...columns,["classes"]:!columns.classes})}>
                            <input checked={columns.classes} type='checkbox' name='classes' onChange={columnHandler}/><p>Патологии</p>
                        </div>
                        <div className='colimn-settings-row' onClick={()=>setColumns({...columns,["raw_prob"]:!columns.raw_prob})}>
                            <input checked={columns.raw_prob} type='checkbox' name='raw_prob' onChange={columnHandler}/><p>Raw Prob</p>
                        </div>
                    </div>
                </div>
            }
            {isImgEditorShown && (
                <div className='edit-div' id='editWindow' >
                    <div className='admin-edit-header'>
                        <div className='comments'>
                            <button onClick={()=>{setShowComments(!showComments);setShowDownloadPanel(false)}}>Коментарии</button>
                            <button onClick={()=>{setShowDownloadPanel(!showDownloadPanel);setShowComments(false)}}>Скачать</button>
                            {showDownloadPanel&&<div className='comments-list'>
                                <div className='comments-button'>
                                    <button onClick={()=>splitImage("original")}>Оригинал</button>
                                </div>
                                <div className='comments-button'>
                                    <button onClick={()=>splitImage("result")}>Результат</button>
                                </div>
                                <div className='comments-button'>
                                    <button onClick={()=>splitImage("concat")}>Объединённый</button>
                                </div>
                            </div>}
                            {showComments&&<div className='comments-list'>
                                {comments.map((item,index)=>(<p key={index}>{item}</p>))}
                                <div className='comments-button'>
                                    <button onClick={()=>{setShowDownloadPanel(false);setShowCommentWindow(!showCommentWindow);setRate(-1)}}>Добавить комментарий</button>
                                </div>
                            </div>}
                            {showCommentWindow&&
                            <div className='comment-window'>
                                <div className='comment-window-input'>
                                    <textarea rows="1" placeholder="Введите комментарий" value={commentText} onChange={handleComment}/>
                                </div>
                                <div className='comments-window-button'>
                                    <button onClick = {()=>sendComment()}> Отправить комментарий </button>
                                    <button onClick = {()=>{setCommentText('');setShowCommentWindow(false)}}>Отмена</button>
                                </div>
                            </div>}
                        </div>
                        {commentsLoading?
                            <div className='rate'></div>
                            :userRate==-1?
                                <div className='rate'>
                                    Нет оценки врача
                                    {/* <div className='agree-rate' onClick={()=>{rate==1?setRate(-1):setRate(1);setShowComments(false);setShowCommentWindow(false)}}><CheckIcon/> Согласен</div>
                                    <div className='disagree-rate' onClick={()=>{rate==0?setRate(-1):setRate(0);setShowComments(false);setShowCommentWindow(false)}}><CloseIcon/>Не согласен</div> */}
                                </div>:
                                userRate==0?
                                    <div className='rate'>
                                        Врач не согласился с заключением ИИ:<br/>({errors.join(', ')})
                                    </div>:
                                    <div className='rate'>
                                        Врач согласился с заключением ИИ
                                    </div>
                            }
                            {rate==1&&<div className='disagree-window'>
                            <div className='disagree-window-title'>Примечание (не обязательно):</div>
                                <div className='comment-window-input'>
                                    <textarea id="auto-expanding-input" rows="1" placeholder="Введите текст примечания" value={commentText} onChange={handleComment}></textarea>
                                </div>
                                <div className='comments-window-button'>
                                    <button onClick = {()=>{setCommentText('');setShowCommentWindow(false);setRate(-1);setRadioValue(-1)}}>Отмена</button>
                                    <button onClick = {()=>sendAgreement()}>Отправить оценку</button>
                                </div>
                            </div>}
                        {rate==0&&<div className='disagree-window'>
                            <div className='disagree-window-title'>Выберите вариант ошибки</div>
                            <div className='disagree-radio'>
                                <div className='disagree-ident'>
                                    <div className='disagree-radio-block'><input id='radio1' type='radio' name='radio' onChange={()=>setRadioValue(0)}></input><label htmlFor='radio1'>Неверное заключение</label></div>
                                    {radioValue==0&&<div className='disagree-radio-ident-block detect-block'>
                                        <div className='disagree-radio-ident'> <input id='detectradio1' type='radio' name='detectradio' value='FP' onChange={()=>setRadioSubValue(0)}></input><label htmlFor='detectradio1'>Ложноположительный результат</label></div>
                                        <div className='disagree-radio-ident'> <input id='detectradio2' type='radio' name='detectradio' value='FN' onChange={()=>setRadioSubValue(1)}></input><label htmlFor='detectradio2'>Ложноотрицательный результат</label></div>
                                    </div>}
                                </div>
                                <div className='disagree-ident'>
                                    <div className='disagree-radio-block'><input id='radio2' type='radio' name='radio' onChange={()=>setRadioValue(1)}></input><label htmlFor='radio2'>Неверная разметка</label></div>
                                    {radioValue==1&&<div className='disagree-radio-ident-block local-block'>
                                        <div className='disagree-radio-ident'><input id='detectradio3' type='checkbox' name='detectcheckbox' className='detectcheckbox' value='WL' onChange={()=>setRadioSubValue(2)}></input><label htmlFor='detectradio3' id='class-block'>Неточное оконтуривание</label></div>
                                        <div className='disagree-radio-ident'><input id='detectradio4' type='checkbox' name='detectcheckbox' className='detectcheckbox' value='WC' onChange={()=>setRadioSubValue(3)}></input><label htmlFor='detectradio4' id='class-block'>Неверная классификация паталогий</label></div>
                                    </div>}
                                </div>
                            </div>
                            <div className='disagree-window-title'>Примечание (не обязательно):</div>
                            <div className='comment-window-input'>
                                <textarea id="auto-expanding-input" rows="1" placeholder='Введите текст примечания' value={commentText} onChange={handleComment}></textarea>
                            </div>
                            <div className='comments-window-button'>
                                <button onClick = {()=>{setCommentText('');setShowCommentWindow(false);setRate(-1);setRadioValue(-1)}}>Отмена</button>
                                <button onClick = {()=>sendRate()}>Отправить оценку</button>
                            </div>
                        </div>}
                        <div className='admin-edit-header-btn' onClick={()=>closeImgEditor()}>
                            <a className='edit-header-link'><CloseIcon sx={{fontSize:50,color:"white"}}/></a>
                        </div>
                    </div>
                    {imageLoading?
                        (<div className='div-loading' id='editimage'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                        ):(
                        <div className='admin-edit-image' id='editimage' onClick={()=>{setShowComments(false);setRate(-1);setRadioValue(-1);setShowDownloadPanel(false)}}>
                            <PrismaZoom style={{display:'flex',justifyContect:'center',alignItem:'center',width:'auto',height:'auto',maxHeight:'100%',maxWidth:'100%'}}>
                                <img id='image' src={url}/>
                            </PrismaZoom>
                        </div>)}
                </div>
            )}
            {detalizationShown&&
                <div className='detalization-active'>
                    <div className='detalization-header'>
                        <div className='detalization-header-info'>
                            Данные за период с {moment(document.getElementById('start').value).format("D MMMM YYYY")} по {moment(document.getElementById('end').value).format("D MMMM YYYY")}
                        </div>
                        <div className='datalization-header-active'>
                            <button onClick={()=>setDetalizationShown(false)}>Закрыть</button>
                        </div>
                    </div>
                    {detalizationLoading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <div className='detalization-body'>
                            <div className='detalization-body-text'>
                                <div className='detalization-text'>Общее количество исследований: {detailCount}</div>
                                <div className='detalization-text'>Норм: {((normCount/detailCount).toFixed(3)*100).toFixed(1)}% Патологий: {((1-(normCount/detailCount).toFixed(3)).toFixed(3)*100).toFixed(1)}%</div>
                            </div>
                            <div className='detalization-body-diagram'>
                                <Pie style={{maxHeight:"100%",maxWidth:"100%",fontColor:'black', background:'white',borderRadius:10,padding:10,width:'auto',height:'auto'}} data={{
                                    labels:["Норма","С патологиями"],
                                    datasets:[
                                        {
                                            data:[normCount,(detailCount-normCount)],
                                            backgroundColor: [
                                                "#63FF84",
                                                "#FF6384",
                                                ],
                                            borderWidth: 1,
                                            borderColor:'grey'
                                        },
                                    ]
                                }}
                                options={{
                                }}/>
                            </div>
                            <div className='pathologies-text'>
                                <div className='pathologies-text-title'>
                                    Распределение патологий
                                </div>
                            </div>
                            <div className='pathologies-diagram'>
                                <Bar style={{maxHeight:"100%",maxWidth:"100%",fontColor:'black', background:'white',borderRadius:10,padding:10,width:'auto',height:'auto'}} data={{
                                        labels:Object.keys(pathologies),
                                        label:'yfpdfybt',
                                        datasets:[
                                            {
                                                label:'',
                                                data:Object.values(pathologies),
                                                backgroundColor: [
                                                    '#7EEB46',
                                                    '#F2BD3A',
                                                    '#DB4A40',
                                                    '#7D3AF2',
                                                    '#38D8E8',
                                                    '#DB4ACC',
                                                    '#4C6FF5',
                                                    '#6AB7F5',
                                                    '#C3EB9B',
                                                    '#EB557F'
                                                ],
                                                borderWidth: 1,
                                                borderColor:'grey'
                                            },
                                        ]
                                    }} options={{
                                        interaction: {
                                            intersect: false,
                                            index:true
                                        },
                                        plugins: {
                                            legend: {
                                                title:"Распределение по патологяим",
                                                display: false,
                                                labels: {
                                                    display: false,
                                                    title:"Распределение по патологяим",
                                                }
                                            },
                                        },
                                        tooltips: {
                                            enabled: true
                                        },
                                        hover: {
                                            animationDuration: 1
                                        },
                                        animation: {
                                        duration: 1,
                                        }

                                        }}/>
                            </div>
                        </div>
                    )}
                </div>
            }
            <div className='admin-stat-table'>
                <div className='admin-stat-table-title'>
                    <label htmlFor="activity-period">Период активности:</label>
                    <select id="activity-period" value={selectedPeriod} onChange={handlePeriodChange}>
                        <option value={moment().subtract(1,'month').format('YYYY-MM-DD')}>Последний месяц</option>
                        <option value={moment().subtract(2,'month').format('YYYY-MM-DD')}>Последние два месяца</option>
                        <option value={moment().subtract(6,'month').format('YYYY-MM-DD')}>Последние полгода</option>
                        <option value={moment('2000-01-01 00:00:00','YYYY-MM-DD').format('YYYY-MM-DD')}>За все время</option>
                    </select>
                    {/* <div className='admin-stat-table-title-text'>Пользователи</div> */}
                </div>
                <div className='admin-stat-table-data'>
                    {loading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <div className='stat-table-data'>
                            <table className='ad-stat-table'>
                                <thead className='ad-stat-thead' >
                                    <tr className='ad-stat-tr'>
                                        <th className='stat-tr-th'><input type='checkbox'  onChange={()=>sel()} id='selectall'/></th>
                                        <th className='stat-tr-th' id='stat-tr-th'><input id='organization' onBlur={()=>document.getElementById('organization').placeholder='Организация'} onFocus={()=>document.getElementById('organization').placeholder=''} type='text' placeholder='Организация' onChange={organizationHandler}/><CloseIcon className='close-icon' title='Очистить поле' sx={{color:'white',fontSize:'14'}} onClick={()=>clearOrganization()}/></th>
                                        <th className='stat-tr-th'>Логин</th>
                                    </tr>
                                </thead>
                                <tbody className='ad-stat-tbody'>
                                    {users.length===0&&(
                                        <tr className='ad-stat-tr'>
                                            <td className='stat-tr-td' colSpan='3'>Нет пользователей</td>
                                        </tr>
                                    )}
                                    {users.map((item)=>
                                        <tr key={item.id} className='ad-stat-tr' onClick={()=>setSelectedUsers({...selectedUsers,[item.id]:!selectedUsers[item.id]})}>
                                            <td className='stat-tr-td'><input id = {item.id} checked={selectedUsers[`${item.id}`]} onChange={()=>setSelectedUsers({...selectedUsers,[item.id]:!selectedUsers[item.id]})} type='checkbox' name='input'/></td>
                                            <td className='stat-tr-td'>
                                                <div className="stat-tr-td-div">
                                                    <div className='stat-status' title={activeUsers.indexOf(item.id)>=0?"Пользователь онлайн":inActiveUsers.indexOf(item.id)>=0?"У пользователя нет доступных исследований":""}>
                                                        {activeUsers.indexOf(item.id)===-1?
                                                        (inActiveUsers.indexOf(item.id)===-1?<div></div>:
                                                            <CircleIcon sx={{color:'#feff00'}} fontSize="10"/>):(
                                                            <CircleIcon sx={{color:'#00FEFF'}} fontSize="10"/>
                                                        )}
                                                    </div>
                                                    <div className='stat-tr-td-info'>
                                                        {item.organization}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='stat-tr-td'>{item.login}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <div className='ad-stat-select'>
                    <select id='ad-select' onChange={()=>handleChange()}>
                        <option value='default'>Выбрать период</option>
                        <option value='current_week'>Текущая неделя </option>
                        <option value='last_week'>Прошлая неделя </option>
                        <option value='current_month'>Текущий месяц </option>
                        <option value='previous_month'>Прошлый месяц </option>
                        <option value='current_quarter'>Текущий квартал </option>
                        <option value='previous_quarter'>Предыдущий квартал</option>
                        <option value='current_year'>Текущий год </option>
                        <option value='previous_year'>Прошлый год </option>
                        <option value='random'>Произвольный период </option>
                    </select>
                </div>
                <div className='admin-stat-table-date'>
                    <input className="admin-stat-table-date-input" id = 'start' type="date" onChange={()=>handleSelect()}/>
                    <input className="admin-stat-table-date-input" id = 'end' type="date" onChange={()=>handleSelect()}/>
                </div>
                <div className='admin-stat-table-date-check'>
                    Общая
                    <label className="switch">
                        <input type="checkbox" id="check" onClick={()=>{if(researches.length!==0)getStatistic(); else sendData()}}/>
                        <span className="slider round"></span>
                    </label>
                    Сравнительная
                </div>
                <div className='admin-stat-table-date-send'>
                    <button onClick={()=>sendData()}>Отобразить данные</button>
                </div>
            </div>
            <div className={bigDiagramm?"admin-stat-diagram-big":"admin-stat-diagram"}>
                <div className='admin-stat-diagram-title'>
                    <div className='admin-stat-diagram-title-container-1'>
                        <div className='admin-stat-diagram-title-text'>
                            Статистика
                        </div>
                    </div>
                    <div className='admin-stat-diagram-title-container-2'>
                        {graphLoading?(
                            <div className='div-loading'>
                            </div>
                        ):(
                            <div className='admin-stat-diagram-title-info'>
                                Исследований за период : {counter}
                            </div>
                        )}
                    </div>
                    <div className='big-diagramm'>
                        {bigDiagramm?
                        (
                            <div className='big-diagramm-icon' onClick={()=>setBigDiagramm(false)}>
                                <CloseFullscreenIcon fontSize='medium' sx={{color:'white'}}/>
                            </div>
                        ):(
                            <div className='big-diagramm-icon' onClick={()=>setBigDiagramm(true)}>
                                <OpenInFullIcon fontSize='medium' sx={{color:'white'}}/>
                            </div>
                        )}
                    </div>
                </div>
                <div className='admin-stat-diagram-data'>
                    {graphLoading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):
                    (<Line style={{background:'white',borderRadius:10,padding:10}}
                        data={{
                        labels:moment(KeyArray[0]).year()===moment(KeyArray[KeyArray.length]).year()? KeyArray.map((val)=>{return moment(val).format('DD.MM')}):KeyArray.map((val)=>{return moment(val).format('DD.MM.YY')}),
                        datasets:mas
                        }}
                        options= {{
                            pointRadius: 1,
                            pointHoverRadius: 7,
                            responsive: true,
                            interaction: {
                              intersect: false,
                            },
                            scales: {
                              y: {
                                gridLines: {
                                    display: !(document.documentElement.scrollWidth<500)
                                },
                                title:{
                                    display:!(document.documentElement.scrollWidth<500),
                                    text:'Исследования'
                                },
                                display: true,
                                beginAtZero: true,
                                precision: 0,
                              },
                              x:{
                                gridLines: {
                                    display: !(document.documentElement.scrollWidth<500)
                                    },
                                title:{
                                display:!(document.documentElement.scrollWidth<500),
                                text:'Дата'},
                            },
                            },
                          }}/>)}
                </div>
                <div className='admin-stat-diagram-btn'>
                    <p>Исследований за всё время: {totalCount}</p>
                    <button id='reserach-table-btn' onClick={()=>detalization()}>Детальная статистика</button>
                </div>
            </div>
            <div className={bigTable?'admin-research-table-active':'admin-research-table'} hidden={tableHidden} id='researchTable'>
                <div className={bigTable?'admin-research-table-close-active':'admin-research-table-close'}>
                    <div className='select-mode'>
                        Режим: 
                        <div className='open-mode'><input type='radio' name='radiomode' id='openmode' checked={itemMode==0} onClick={()=>setItemMode(0)}></input><label htmlFor='openmode'>Открытие</label></div>
                        <div className='copy-mode'><input type='radio' name='radiomode' id='copymode' checked={itemMode==1} onClick={()=>setItemMode(1)}></input><label htmlFor='copymode'>Копирование</label></div>
                    </div>
                    <div className='pagination-block'>
                        <div className='select-count'>
                            Отображать:
                            <select id="elements" onChange={handleElements}>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={400}>400</option>
                                <option value={800}>800</option>
                            </select>
                        </div>
                        <div className='serach-field'>
                            <SearchIcon/>
                            <input type='text' id = 'research' placeholder='Введите Study/SOP'/>
                            <CloseIcon onClick={()=>document.getElementById('research').value=''} sx={{color:'lightgray'}} titleAccess={"Очистить"}/>
                            <button onClick={()=>searchStudy()} style={{marginRight:5}}>Найти</button>
                        </div>
                        <Pagination count={pages} siblingCount={0} page={page} onChange={handlePage} hideNextButton={page==pages} defaultPage={1}/>
                            {page==pages&&
                                <div className='pag-div' onClick={()=>nextPage()}>
                                    Ещё
                                </div>
                            }
                    </div>
                    <div className='table-settings' id='activeSetting' onClick={()=>setSetting(!setting)}><SettingsIcon sx={{color:'white'}}/></div>
                    {bigTable?
                        (<button id='researchTableBtn' onClick={()=>setBigTable(false)}>Свернуть табицу</button>):
                        (<button id='researchTableBtn' onClick={()=>setBigTable(true)}>Развернуть таблицу</button>)
                    }
                    <button onClick={()=>hideTable()}>Закрыть</button>
                </div>
                <div className={bigTable?'admin-research-table-data-active':'admin-research-table-data'}>
                    {tableLoading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(<Table researches={researches} sortTable={sortTable} getImage={getImage} sort={sort} setSort={setSort} columns={columns} copyToClipboard={copyToClipboard} itemMode={itemMode}/>)
                    }
                </div>
            </div>
        </div>
    );
};
export default Adminstat;